import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, DetachedRouteHandle, Routes } from '@angular/router';
import { RouteReuseStrategy } from '@angular/router';
import { languageGuard, languageResolver, LanguageUtil, navigationResolver } from '@dextools/core';
import { Chain } from '@dextools/blockchains';
import { AppPage } from './shared/models/app-page.model';
import { aliasResolver } from '@dextools/blockchains/resolvers';

const language = LanguageUtil.getCurrentStoredLanguage('configApp');

const LEGACY_ROUTES: Routes = [
  {
    path: 'multi-swap', // not using enum because this url is legacy
    redirectTo: `${language}/${Chain.Ethereum}/${AppPage.Multiswap}`,
  },
  {
    path: 'uniswap/wallet-info', // not using enum because this url is legacy
    redirectTo: `${language}/${Chain.Ethereum}/${AppPage.WalletInfo}`,
  },
  {
    path: 'wallet-info', // not using enum because this url is legacy
    redirectTo: `${language}/${Chain.Ethereum}/${AppPage.WalletInfo}`,
  },
  {
    path: 'stats', // not using enum because this url is legacy
    redirectTo: `${language}/${Chain.Ethereum}/${AppPage.Stats}`,
  },
  {
    path: 'multichart',
    redirectTo: `${language}/multichart`,
  },
  {
    path: 'config', // does not exist anymore (settings are now in a side panel)
    redirectTo: language,
  },
  {
    path: 'dextswap',
    redirectTo: `${language}/${Chain.Ethereum}/dextswap`,
  },
];

const LANGUAGE_REDIRECTS: Routes = [
  {
    path: `:chain/${AppPage.Stats}`,
    redirectTo: `${language}/:chain/${AppPage.Stats}`,
  },
  {
    path: `:chain/${AppPage.Multiswap}`,
    redirectTo: `${language}/:chain/${AppPage.Multiswap}`,
  },
  {
    path: `:chain/${AppPage.WalletInfo}`,
    redirectTo: `${language}/:chain/${AppPage.WalletInfo}`,
  },
  {
    path: `:chain/${AppPage.WalletInfo}/:wallet`,
    redirectTo: `${language}/:chain/${AppPage.WalletInfo}/:wallet`,
  },
  {
    path: `:chain/${AppPage.Dextswap}/`,
    redirectTo: `${language}/:chain/${AppPage.Dextswap}`,
  },
  {
    path: `:chain/${AppPage.Dextswap}/:token2`,
    redirectTo: `${language}/:chain/${AppPage.Dextswap}/:token2`,
  },
  {
    path: `:chainOrExchange/${AppPage.LiveNewPairs}`,
    redirectTo: `${language}/:chainOrExchange/${AppPage.LiveNewPairs}`,
  },
  {
    path: `:chainOrExchange/${AppPage.BigSwap}`,
    redirectTo: `${language}/:chainOrExchange/${AppPage.BigSwap}`,
  },
  {
    path: `:chainOrExchange/${AppPage.PairExplorer}`,
    redirectTo: `${language}/:chainOrExchange/${AppPage.PairExplorer}`,
  },
  {
    path: `:chainOrExchange/${AppPage.PairExplorer}/:pair`,
    redirectTo: `${language}/:chainOrExchange/${AppPage.PairExplorer}/:pair`,
  },
  {
    path: `token/:alias`,
    redirectTo: `${language}/token/:alias`,
  },
];

export const ROOT_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: language,
  },
  {
    path: 'user',
    redirectTo: `${language}/user`,
  },
  {
    path: 'max-tabs-error',
    loadComponent: () =>
      import('./shared/pages/max-tabs-error-page/max-tabs-error-page.component').then((mod) => mod.MaxTabsErrorPageComponent),
    data: {
      isEmptyPage: true,
    },
  },
  {
    path: ':language/user',
    canActivate: [languageGuard],
    resolve: {
      currentLanguage: languageResolver,
    },
    loadChildren: () => import('./user/user.routes').then((mod) => mod.USER_ROUTES),
  },
  {
    path: `:language/${AppPage.PrivacyPolicy}`,
    loadChildren: () => import('./privacy-policy/privacy-policy.routes').then((mod) => mod.PRIVACY_POLICY_ROUTES),
  },
  // TODO - UNCOMMENT WHEN THERE IS A NEW CHALLENGE
  // {
  //   path: `:language/${AppPage.Challenge}`,
  //   canActivate: [languageGuard],
  //   resolve: {
  //     currentLanguage: languageResolver,
  //   },
  //   data: {
  //     ignoreAnimation: true,
  //   },
  //   loadChildren: () => import('./challenge/challenge.routes').then((mod) => mod.CHALLENGE_ROUTES),
  // },
  {
    path: `:language/${AppPage.Simulator}`,
    canActivate: [languageGuard],
    resolve: {
      currentLanguage: languageResolver,
    },
    data: {
      disableAnimation: true,
    },
    loadChildren: () => import('./simulator/simulator.routes').then((mod) => mod.SIMULATOR_ROUTES),
  },
  {
    path: `:language/multichart`,
    loadChildren: () => import('./exchanges/pages/multichart.routes').then((mod) => mod.MULTICHART_ROUTES),
  },

  ...LEGACY_ROUTES,
  ...LANGUAGE_REDIRECTS,
  /* add airdrops only in dev environments
   * It is in this place as it is not needed to pass the chain as a parameter
   */
  ...(process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'local' ||
  process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'pr-preview' ||
  process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'demo'
    ? [
        {
          path: `:language/${AppPage.Airdrops}`,
          canActivate: [languageGuard],
          resolve: {
            currentLanguage: languageResolver,
          },
          loadChildren: () => import('./airdrops/airdrops.routes').then((mod) => mod.AIRDROPS_ROUTES),
        },
      ]
    : []),
  {
    path: ':language',
    loadChildren: () => import('./exchanges/pages/home.routes').then((mod) => mod.HOME_ROUTES),
  },
  {
    path: ':chain',
    pathMatch: 'full',
    redirectTo: `${language}/:chain`,
  },
  {
    path: `:language/:chainOrExchange/${AppPage.PairExplorer}`,
    loadChildren: () => import('./exchanges/pages/pair-explorer.routes').then((mod) => mod.PAIR_EXPLORER_ROUTES),
  },
  // Url to alias in pair-explorer
  {
    path: `:language/token/:alias`,
    loadComponent: () => import('./exchanges/pages/pairexplorer/pair-explorer-page.component').then((mod) => mod.PairExplorerPageComponent),
    canActivate: [languageGuard],
    resolve: {
      currentAlias: aliasResolver,
      currentLanguage: languageResolver,
      currentNavigation: navigationResolver,
    },
  },
  {
    path: `:language/:chainOrExchange/${AppPage.LiveNewPairs}`,
    loadChildren: () => import('./exchanges/pages/pools-creator.routes').then((mod) => mod.POOLS_CREATOR_ROUTES),
  },
  {
    path: `:language/:chainOrExchange/${AppPage.BigSwap}`,
    loadChildren: () => import('./exchanges/pages/big-swap.routes').then((mod) => mod.BIG_SWAP_ROUTES),
  },
  {
    path: `:language/:chain/${AppPage.BaseFun}`,
    loadChildren: () => import('./exchanges/pages/basefun.routes').then((mod) => mod.BASEFUN_ROUTES),
  },
  {
    path: `:language/:chain/${AppPage.WalletInfo}`,
    loadChildren: () => import('./exchanges/pages/wallet-info.routes').then((mod) => mod.WALLET_INFO_ROUTES),
  },
  {
    path: `:language/:chain/${AppPage.Multiswap}`,
    loadChildren: () => import('./exchanges/pages/multiswap.routes').then((mod) => mod.MULTISWAP_ROUTES),
  },
  {
    path: `:language/:chain/${AppPage.Stats}`,
    loadChildren: () => import('./exchanges/pages/stats.routes').then((mod) => mod.STATS_ROUTES),
  },
  {
    path: `:language/:chain/${AppPage.Dextswap}`,
    loadChildren: () => import('./aggregator/aggregator.routes').then((mod) => mod.AGGREGATOR_ROUTES),
  },
  {
    path: '**', // default route (should come last, it is selected when no other routes matched)
    redirectTo: language,
  },
];

/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * RouteReuseStrategy to be used when defining `onSameUrlNavigation: 'reload'` in the RouterModule config.
 *
 * As described in https://github.com/angular/angular/issues/21115#issuecomment-645588886
 * Demoed in https://stackblitz.com/edit/angular-ivy-router-base-2hgd2t
 */
@Injectable({
  // see: https://angular.io/api/core/Injectable#providedIn
  // eslint-disable-next-line @angular-eslint/use-injectable-provided-in
  providedIn: null,
})
export class CustomRouteReuseStrategy extends RouteReuseStrategy {
  /**
   * Whether the given route should detach for later reuse.
   * Always returns false for `BaseRouteReuseStrategy`.
   *
   * @param route - The route that will be detached
   * @returns false
   */
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  /**
   * A no-op; the route is never stored since this strategy never detaches routes for later re-use.
   *
   * @param route - The route that will be stored
   * @param detachedTree - The detached route
   */
  public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    // noop
  }

  /**
   * Returns `false`, meaning the route (and its subtree) is never reattached
   *
   * @param route - The route that will be checked
   * @returns false
   */
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  /**
   * Returns `null` because this strategy does not store routes for later re-use.
   *
   * @param route - The route that will be retrieved
   * @returns null
   */
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  /**
   * Determines if a route should be reused.
   *
   * IMPORTANT: This strategy returns `false` always because even routes with the same url should never be reused, which means that
   * the routed component should be destroyed and re-instantiated so that all lifecycle hooks are executed.
   * For example: changing pairs in PairExplorer.
   *
   * @param future - The route that will be displayed
   * @param curr - The route that is currently displayed
   * @returns false
   */
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return false;
  }
}

/* eslint-enable @typescript-eslint/no-unused-vars */
