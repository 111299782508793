import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT, Location, NgClass, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import type { AfterViewInit, OnDestroy, OnInit, Signal } from '@angular/core';
import {
  afterNextRender,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  effect,
  ElementRef,
  HostListener,
  Inject,
  inject,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { IsActiveMatchOptions } from '@angular/router';
import { ChildActivationEnd, NavigationEnd, Router } from '@angular/router';
import { InterestLinkDataDirective } from '@dextools/analytics';
import { Chain, ChainUtil, regexTokenDefaultUrl } from '@dextools/blockchains';
import { ExchangeService } from '@dextools/blockchains/services';
import type { Language } from '@dextools/core';
import {
  AuthenticationService,
  Environment,
  Platform,
  Role,
  RouterLinkDirective,
  RoutingService,
  SettingsService,
  Theme,
} from '@dextools/core';
import { CustomIconComponent } from '@dextools/icons';
import type { Resolution } from '@dextools/ui-utils';
import { DeviceService, IfDeviceSizeDirective } from '@dextools/ui-utils';
import { PanelComponent, PromoTagComponent } from '@dextools/ui/components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAndroid, faApple, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCode, faFire, faGlobe, faHouse, faMinusCircle, faParachuteBox, faPlusCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { faFlag, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCommentAltEdit, faGear } from '@fortawesome/pro-regular-svg-icons';
import {
  faChartMixedUpCircleDollar,
  faDisplayChartUp,
  faFileCircleCheck,
  faGearCode,
  faMoneyCheckDollar,
} from '@fortawesome/pro-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TippyDirective } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { filter, from, fromEvent, Subject, tap } from 'rxjs';
import { SettingsPanelComponent } from '../../../shared/components/settings-panel/settings-panel.component';
import { SocialIconsPanelComponent } from '../../../shared/components/social-icons-panel/social-icons-panel.component';
import { APP_STORE_URL } from '../../../shared/constants/app-mobile.constants';
import { TOKEN_CREATOR_URL } from '../../../shared/constants/apps.constants';
import { SOCIALS_PROMO_END_DATE } from '../../../shared/constants/promo.constants';
import { AppPage } from '../../../shared/models/app-page.model';
import type { DextoolsAppConfig } from '../../../shared/models/config.model';
import type { DextoolsSettingsService } from '../../../shared/services/settings/settings.service';
import { AppPageUtil } from '../../../shared/utils/app-pages.util';
import { SideMenuMobileService } from '../../services/side-menu-mobile.service';

const SHOW_MENU_BUTTON_MILLIS = 2_000;
const PRODUCT_LIST_HEIGHT = 565; // Change it if number of elements in products list changes

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('hoverAnimation', [state('in', style({ opacity: 1 })), transition(':enter', [style({ opacity: 0 }), animate(200)])]),
  ],
  standalone: true,
  imports: [
    CustomIconComponent,
    FontAwesomeModule,
    IfDeviceSizeDirective,
    InterestLinkDataDirective,
    NgClass,
    NgTemplateOutlet,
    PanelComponent,
    PromoTagComponent,
    RouterLinkDirective,
    SettingsPanelComponent,
    SocialIconsPanelComponent,
    TippyDirective,
    TranslateModule,
    NgOptimizedImage,
  ],
})
export class SideMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly Chain = Chain;
  protected readonly AppPage = AppPage;
  protected context = this._environment.app_scope;
  private readonly _sideMenuChange$ = new Subject<void>();
  public showUpdateLinksPanel = false;

  protected productListClass = 'bottom-initial';

  @ViewChild('sidebar')
  protected sidebar: ElementRef<HTMLElement>;

  @ViewChild('productsLink')
  protected productsLink: ElementRef<HTMLElement>;

  @HostListener('window:scroll', ['$event'])
  protected onScroll(event: Event) {
    this.showMenuButton = true;
    if (this.timeoutButton) {
      clearTimeout(this.timeoutButton);
    }
    this.timeoutButton = setTimeout(() => (this.showMenuButton = false), SHOW_MENU_BUTTON_MILLIS);

    // IMPORTANT: stop the scroll here so that the main page (and any other component) in the background is not scrolled as well
    // to avoid an ugly/confusing effect of the scrollbar moving in iPhone and also any unwanted side effects
    if (this.showMenu) {
      event.stopImmediatePropagation();
    }
  }

  protected Theme = Theme;
  protected theme = Theme.Dark;

  protected isNotConnected = false;
  protected roleStr = '';
  protected showMenuButton = true;
  protected timeoutButton: ReturnType<typeof setTimeout> | undefined;

  protected chainSelected = this._exchangeService.chain ?? Chain.Ethereum;
  protected chainNewPairsBotUrl?: string;
  protected chainDefaultPair: string;
  protected userId: string | null = null;
  protected sidebarExpanded = false;

  protected mediaDevice: Signal<Resolution>;
  protected isAdmin = false;

  protected tokenCreatorUrl = TOKEN_CREATOR_URL;

  protected get showMenu(): boolean {
    return this._showMenu;
  }

  protected set showMenu(value: boolean) {
    this._showMenu = value;
    // Enable/disable scrolling in the main page when opening/closing the side menu
    this._document.body.style.overflow = this._showMenu && this.mediaDevice() !== 'desktop' ? 'hidden' : 'auto';
  }

  private _showMenu = false;

  private readonly _cachedActiveRoutes: Map<string, boolean>;
  private readonly _isMobileApp: boolean = false;
  private readonly _isIOS: boolean = false;

  protected toggleSettingsPanel = false;

  protected language: Language;

  protected showProducts = false;

  protected readonly appStoreUrl = APP_STORE_URL;

  // TODO. Remove when promo is over
  protected showSocialsPromo = Date.now() < SOCIALS_PROMO_END_DATE;

  private readonly _destroyRef = inject(DestroyRef);

  public readonly isAirdropsAvailable =
    process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'local' ||
    process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'pr-preview' ||
    process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'demo';

  protected readonly icons = {
    faHouse,
    faChartMixedUpCircleDollar,
    faDisplayChartUp,
    faFire,
    faGlobe,
    faMoneyCheckDollar,
    faCode,
    faGearCode,
    faAndroid,
    faApple,
    faYoutube,
    faUser,
    faTimes,
    faFlag,
    faGear,
    faCommentAltEdit,
    faPlusCircle,
    faMinusCircle,
    faFileCircleCheck,
    faParachuteBox,
  };

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _exchangeService: ExchangeService,
    private readonly _router: Router,
    private readonly _settingsService: SettingsService<DextoolsAppConfig>,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _deviceService: DeviceService,
    private readonly _sideMenuService: SideMenuMobileService,
    private readonly _environment: Environment,
    private readonly _routingService: RoutingService,
    private readonly _modalService: NgbModal,
    private readonly _location: Location,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {
    this._cachedActiveRoutes = new Map<string, boolean>();
    this._isMobileApp = !!this._authenticationService.deviceId; // the mobile app sets this id ALWAYS
    this._isIOS = this._isMobileApp && this._authenticationService.devicePlatform === Platform.Ios;
    this.language = this._settingsService.language;
    this.chainDefaultPair = ChainUtil.getDefaultPairByChain(this.chainSelected);
    this.chainNewPairsBotUrl = ChainUtil.getChainData(this.chainSelected).newPairsBotUrl;
    this._settingsService
      .getConfigChanged$('language')
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((language) => {
        this.language = language;
      });

    afterNextRender({
      read: () => {
        this._calculateProductListPosition();

        fromEvent(window, 'resize')
          .pipe(takeUntilDestroyed(this._destroyRef))
          .subscribe(() => {
            this._calculateProductListPosition();
          });
      },
    });

    this.mediaDevice = this._deviceService.getDeviceConfig('displayResolution');
    effect(() => {
      this.mediaDevice();
      this._sideMenuChange$.next();

      // reset sidebar to its initial state
      this.sidebarExpanded = false;
      this.showMenu = false;
    });
  }

  public ngOnInit() {
    this._exchangeService.chain$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((chain) => chain !== this.chainSelected),
        tap((chain) => {
          this.chainSelected = chain ?? Chain.Ethereum;
          this.chainNewPairsBotUrl = ChainUtil.getChainData(this.chainSelected).newPairsBotUrl;
          this.chainDefaultPair = ChainUtil.getDefaultPairByChain(this.chainSelected);
          this._cdRef.detectChanges();
        }),
      )
      .subscribe();

    this.timeoutButton = setTimeout(() => (this.showMenuButton = false), SHOW_MENU_BUTTON_MILLIS);

    /* When route navigation ends, Hamburger icon is added back to the header in mobile. */
    this._router.events
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((event) => event instanceof NavigationEnd),
        tap(() => {
          // clear cache on every successful route change
          this._cachedActiveRoutes.clear();
        }),
      )
      .subscribe();

    // Hide menu on back & front navigation buttons
    this._router.events
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((event) => event instanceof ChildActivationEnd),
        tap(() => {
          this.showMenu = false;
          this._cdRef.markForCheck();
        }),
      )
      .subscribe();

    this._settingsService.onThemeChange$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((theme) => theme !== this.theme),
      )
      .subscribe((theme) => {
        this.theme = theme;
        this._cdRef.detectChanges();
      });

    this._authenticationService.currentUser$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data_user) => {
      this.isNotConnected = !data_user;
      this.userId = data_user?.id?.toLowerCase() ?? null;

      if (data_user?.plan === Role.P100) {
        this.roleStr = 'premium';
      } else if (data_user?.plan === Role.P20) {
        this.roleStr = 'standard';
      } else {
        this.roleStr = 'free';
      }
      this.isAdmin = !!data_user && (data_user.isAdmin || data_user.isSuperAdmin);
      this._cdRef.detectChanges();
    });

    this._sideMenuService.toggleMenuObs
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        tap((showMenu) => {
          this.showMenu = showMenu;
          this._cdRef.detectChanges();
        }),
      )
      .subscribe();
  }

  public ngAfterViewInit() {
    fromEvent(this.sidebar.nativeElement, 'mouseenter')
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.sidebarExpanded = true;
        this._cdRef.detectChanges();
      });
    fromEvent(this.sidebar.nativeElement, 'mouseleave')
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.sidebarExpanded = false;
        this.showProducts = false;
        this._cdRef.detectChanges();
      });
  }

  protected toggleMenu() {
    if (this.mediaDevice() !== 'desktop') {
      this._sideMenuService.toggleMenu();
      this._cdRef.detectChanges();
    }
  }

  protected changeTheme() {
    const isDarkTheme = this.theme === Theme.Light;
    this._settingsService.changeConfig({ dark_theme: isDarkTheme });
  }

  public ngOnDestroy() {
    clearTimeout(this.timeoutButton);
  }

  protected isPageAvailableInChain(page: AppPage, chain: Chain): boolean {
    // TODO: Multiswap won't be available in iOS for now. Remove this block once this should be available
    if ((page === AppPage.Multiswap || page === AppPage.BaseFun) && this._isIOS) {
      return false;
    }

    return AppPageUtil.isPageAvailableInChain(page, chain);
  }

  protected isRouteActive(path: string, exact = false) {
    // Cache routes active state for performance issues with ChangeDetectorStrategy
    let isActive = this._cachedActiveRoutes.get(path);
    if (isActive != null) {
      return isActive;
    }

    const matchOptions: IsActiveMatchOptions = exact
      ? { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' }
      : { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' };

    const currentUrl = this._location.path();
    isActive = this._router.isActive(path, matchOptions) || (!!regexTokenDefaultUrl.test(currentUrl) && !!regexTokenDefaultUrl.test(path));
    this._cachedActiveRoutes.set(path, isActive);

    return isActive;
  }

  protected navigateToPage(event: MouseEvent, pageUrl: string, checkCurrentRouteExactMatch = false, chain: Chain = Chain.Ethereum) {
    const walletInfoRegexURL = new RegExp(AppPage.WalletInfo);
    if (walletInfoRegexURL.test(pageUrl) && this.isNotConnected) {
      return;
    }

    if (event.ctrlKey || event.metaKey) {
      // we need to take care of the Ctrl+click (open in a new tab) because of the 'false' we add in the 'click' handler
      this._routingService.windowNavigate(pageUrl, '_blank', 'noopener');
      return;
    }

    let isRouteActive = this.isRouteActive(`${this.language}${pageUrl}`, checkCurrentRouteExactMatch);

    // For Pair Explorer, navigation should be triggered only if the current pair is different from the default pair
    const regexPairExplorerDefaultPairUrl = new RegExp(
      `/${this.chainSelected}/${AppPage.PairExplorer}/${ChainUtil.getDefaultPairByChain(chain)}`,
    );
    if (regexPairExplorerDefaultPairUrl.test(pageUrl) && regexPairExplorerDefaultPairUrl.test(this._location.path())) {
      isRouteActive = true;
    } else if (regexPairExplorerDefaultPairUrl.test(pageUrl) && !regexPairExplorerDefaultPairUrl.test(this._location.path())) {
      isRouteActive = false;
    }

    if (!isRouteActive) {
      // here we care only about those pages that are not contained by the ExchangePageComponent
      // because that component already handles the chain switch ;)
      // - PairExplorer (special case: current pair vs default pair)
      // - Multiswap
      // - WalletInfo
      // - Dextswap

      this._routingService.navigate(pageUrl).then(() => {
        this.toggleMenu();
        this._cdRef.detectChanges();
      });
    }
  }

  protected goToUserAccount() {
    this.toggleMenu();
    this._routingService.navigate('/user/account');
  }

  protected async disconnect() {
    try {
      await this._authenticationService.disconnectWallet();
      this._authenticationService.logout();
    } catch (error) {
      console.error(`Could not disconnect`, error);
    }
  }

  protected toggleSettings(open: boolean) {
    this.toggleSettingsPanel = open;
    this._cdRef.detectChanges();
  }

  protected showVideoModal() {
    (this._settingsService as DextoolsSettingsService).showAcademyModal(true);
    if (this.mediaDevice() !== 'desktop') {
      this.toggleMenu();
    }
  }

  protected toggleProducts() {
    this.showProducts = !this.showProducts;
    this._cdRef.detectChanges();
  }

  public openSocialModalOrPanel() {
    if (this.mediaDevice() === 'desktop') {
      from(import('../../../shared/components/social-icons-modal/social-icons-modal.component'))
        .pipe(
          tap((module) => {
            this._modalService.open(module.SocialIconsModalComponent, { centered: true, scrollable: true });
          }),
        )
        .subscribe();
    } else {
      this.toggleUpdateLinksPanel(true);
    }
  }

  public toggleUpdateLinksPanel(open: boolean) {
    this.showUpdateLinksPanel = open;
    this._cdRef.detectChanges();
  }

  protected get userAreaUrl() {
    return `https://www.dextools.io/user-area/${this._settingsService.language}/dashboard`;
  }

  // Dynamically calculate bottom position of the products list depending on window height
  private _calculateProductListPosition() {
    this.productListClass =
      window.innerHeight - this.productsLink?.nativeElement.getBoundingClientRect().top <= PRODUCT_LIST_HEIGHT
        ? 'bottom-5'
        : 'bottom-initial';

    this._cdRef.detectChanges();
  }

  protected getEventData(section: string) {
    return {
      event: 'click_menu',
      eventCategory: 'main_menu',
      eventAction: 'click_link',
      eventName: section,
    };
  }
}
