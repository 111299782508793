<div
  class="message-container__info"
  [ngClass]="{
    success: toastPackage.toastType === 'toast-success',
    error: toastPackage.toastType === 'toast-error',
  }"
>
  <div class="type-alert-icon">
    @if (toastPackage.toastType === 'toast-success') {
      <fa-icon [icon]="icons.faCheck"></fa-icon>
    }
    @if (toastPackage.toastType === 'toast-error') {
      <fa-icon [icon]="icons.faTimes"></fa-icon>
    }
    @if (toastPackage.toastType === 'toast-info') {
      <fa-icon [icon]="icons.faInfoCircle"></fa-icon>
    }
  </div>
  <div class="text-container">
    @if (title) {
      <div [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }}
      </div>
    }
    @if (message && options.enableHtml) {
      <div role="alert" [class]="options.messageClass" [innerHTML]="message"></div>
    }
    @if (message && !options.enableHtml) {
      <div role="alert" [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
      </div>
    }
  </div>
  @if (options.closeButton) {
    <a (click)="remove()" class="close-btn">
      <fa-icon [icon]="icons.faTimes"></fa-icon>
    </a>
  }
  @if (options.progressBar) {
    <div>
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  }
</div>
