import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, RouteReuseStrategy, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { CustomRouteReuseStrategy, ROOT_ROUTES } from './app.routes';
import type { ApplicationConfig } from '@angular/core';
import { APP_INITIALIZER, importProvidersFrom, PLATFORM_ID, provideZoneChangeDetection, RendererFactory2 } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory } from './translation.config';
import {
  CAPTCHA_CHALLENGE_CONFIG,
  CAPTCHA_CONFIG,
  CORE_HTTP_INTERCEPTOR_PROVIDERS,
  Environment,
  SCRIPT_CONFIG,
  SECURED_URLS,
  SeoService,
  SettingsService,
} from '@dextools/core';
import { provideSolanaWalletStoreConfig } from '@dextools/solana-wallet-adapter';
import { provideToastr } from 'ngx-toastr';
import { BREAKPOINT_DESKTOP, BREAKPOINT_MOBILE, BREAKPOINT_MOBILE_TABLET } from '@dextools/ui';
import {
  arrowDownIcon,
  arrowUpIcon,
  bigSwapIcon,
  gemIcon,
  liveNewPairsIcon,
  mintIcon,
  moreIcon,
  multichartIcon,
  multiswapIcon,
  newPairsBotIcon,
  nftIcon,
  pairExplorerIcon,
  priceBotIcon,
  repeatIcon,
  statsIcon,
  updateIcon,
  variationDownIcon,
  variationUpIcon,
  walletInfoIcon,
} from '@dextools/icons/constants';
import { CustomIconsService } from '@dextools/icons';
import { environment } from '../environments/environment';
import { isPlatformBrowser, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppInitService } from './app-init.service';
import { Chain } from '@dextools/blockchains';
import type { RxRenderStrategiesConfig } from '@rx-angular/cdk/render-strategies';
import { RX_RENDER_STRATEGIES_CONFIG } from '@rx-angular/cdk/render-strategies';
import { CHART_CONFIG } from '@dextools/trading-view';
import { CHALLENGE_CONFIG } from './challenge/models/challenge/challenge.model';
import { ANALYTICS_CONFIG } from '@dextools/analytics';
import { PROCESS_LINKS_LIST } from './shared/constants/analytics-process-links.constants';
import { BREAKPOINTS_CONFIG } from '@dextools/ui-utils';
import { COINZILLA_URL } from '@dextools/marketing';
import { Breakpoints } from '@angular/cdk/layout';
import { DextoolsSettingsService } from './shared/services/settings/settings.service';
import { AppPageUtil } from './shared/utils/app-pages.util';
import { AppPage } from './shared/models/app-page.model';
import type { Observable } from 'rxjs';
import { forkJoin, switchMap, take } from 'rxjs';
import { CommonUtil } from '@dextools/utils';
import { ConfigPagesService, ExchangeService } from '@dextools/blockchains/services';
import { ExchangesResolver } from '@dextools/blockchains/resolvers';
import { ToastAlertComponent } from './shared/components/toast-alert/toast-alert.component';

/**
 * See: https://www.rx-angular.io/docs/cdk/render-strategies
 */
const CUSTOM_RX_ANGULAR_CONFIG: RxRenderStrategiesConfig<string> = {
  primaryStrategy: 'local',
  patchZone: false, // this applies to all *rxLet directives
};

const PROD_ONLY_CAPTCHA_CHALLENGE_CONFIG =
  process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'prod'
    ? [
        {
          provide: CAPTCHA_CHALLENGE_CONFIG,
          useValue: {
            siteKey: '0x4AAAAAAAJbVdZnh74mHA3l',
          },
        },
      ]
    : [];

/**
 *
 * Factory function to instantiate the settings service
 *
 * @param location - The location service
 * @param translate - The translation service
 * @param seoService - The SEO service
 * @param rendererFactory - The renderer factory
 *
 * @returns DextoolsSettingsService The settings service
 */
export function settingsServiceFactory(
  location: Location,
  translate: TranslateService,
  seoService: SeoService,
  rendererFactory: RendererFactory2,
): DextoolsSettingsService {
  return new DextoolsSettingsService(location, translate, seoService, rendererFactory);
}

/**
 * Get the corresponding page according to the given URL
 *
 * @param url - Browser's url
 *
 * @returns The corresponding page if is recognized, `null` otherwise
 */
export function getPageFromUrl(url: string): string | null {
  const page = AppPageUtil.getAppPageFromUrl(url);

  return page === AppPage.Dashboard ? 'dashboard' : page;
}

/**
 * Get the corresponding hierarchy according to the given URL
 *
 * @param url - Browser's url
 *
 * @returns The corresponding hierarchy if is a desired one, `null` otherwise
 */
function getHierarchy3(url: string): string {
  const urlWithHierarchy3Regex =
    /.*\/(pairs|gainers|losers|socials-recently-updated|exchanges|upcoming-liquidity-unlocks|recent-liquidity-unlocks|upcoming-token-unlocks|recent-token-unlocks)/;

  const match = url.match(urlWithHierarchy3Regex);

  return match ? match[1] : '';
}

/**
 * getSecuredUrls - Array secured urls
 *
 * @returns string[]- string urls
 */
export function getSecuredUrls(): string[] {
  const securedUrl: string[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (environment.local_prod_url) {
    securedUrl.push(`${environment.local_prod_url}/challenge-api/`);
  } else {
    securedUrl.push(`${environment.host}/challenge-api/`);
  }
  return securedUrl;
}

/**
 * getChallengeUrl - Get Url for challenge page
 *
 * @param hostOverride - host provided to match the url
 *
 * @returns string[]- string urls
 */
export function getChallengeUrl(hostOverride?: string | null): string {
  const host = hostOverride ?? environment.host;
  return `${host}/challenge-api/`;
}

/**
 * Method called in the Angular APP_INITIALIZER hook.
 *
 * @param platformId - A token that indicates whether the app is running on Server or Browser
 * @param initService - The AppInit service
 * @param exchangeService - The exchange service
 * @param exchangesResolver - The exchanges resolver
 *
 * @param configPagesService - The config pages service
 * @returns Function A function that returns an observable that will emit when the initialization is complete
 */
export function initApp(
  platformId: object,
  initService: AppInitService,
  exchangeService: ExchangeService,
  exchangesResolver: ExchangesResolver,
  configPagesService: ConfigPagesService,
): () => Observable<unknown> {
  return () => {
    const appUrl = isPlatformBrowser(platformId) ? window.location.href : 'https://www.dextools.io';
    const fullUrl = new URL(appUrl);

    // Obtain the current chain according to the URL
    const supportedChains = CommonUtil.convertEnumToArray(Chain);
    const chainMatch = fullUrl.pathname.match(supportedChains.join('|'));

    if (chainMatch !== null) {
      exchangeService.chain = chainMatch[0] as Chain;
    }

    return initService.init(fullUrl).pipe(
      take(1),
      switchMap(() => {
        // trigger Http requests simultaneously
        return forkJoin([
          exchangesResolver.initialResolution(exchangeService.chain),
          configPagesService.fetchConfigFromPage$(process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'prod' ? 'features_app' : 'features_stage'),
          configPagesService.fetchCategoriesConfig$(),
        ]);
      }),
    );
  };
}

/**
 * Method called in the Angular APP_INITIALIZER hook.
 *
 * @param customIconsService - Service to add (register) custom icons
 *
 * @returns Function A function that returns void when the initialization is complete
 */
export function initCustomIcons(customIconsService: CustomIconsService): () => void {
  return () => {
    // OWN CUSTOM icons
    customIconsService.addIcons([
      arrowDownIcon,
      arrowUpIcon,
      bigSwapIcon,
      gemIcon,
      liveNewPairsIcon,
      mintIcon,
      moreIcon,
      multichartIcon,
      multiswapIcon,
      newPairsBotIcon,
      nftIcon,
      pairExplorerIcon,
      priceBotIcon,
      repeatIcon,
      statsIcon,
      updateIcon,
      variationDownIcon,
      variationUpIcon,
      walletInfoIcon,
    ]);
  };
}

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    provideRouter(
      ROOT_ROUTES,
      withRouterConfig({ onSameUrlNavigation: 'reload' }),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient, Environment],
        },
      }),
    ),
    provideSolanaWalletStoreConfig({ autoConnect: false }),
    provideToastr({ toastComponent: ToastAlertComponent }),
    ...CORE_HTTP_INTERCEPTOR_PROVIDERS,
    { provide: Environment, useValue: environment },
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    {
      provide: SettingsService,
      useFactory: settingsServiceFactory,
      deps: [Location, TranslateService, SeoService, RendererFactory2],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [PLATFORM_ID, AppInitService, ExchangeService, ExchangesResolver, ConfigPagesService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initCustomIcons,
      deps: [CustomIconsService],
      multi: true,
    },
    {
      provide: RX_RENDER_STRATEGIES_CONFIG,
      useValue: CUSTOM_RX_ANGULAR_CONFIG,
    },
    {
      provide: CAPTCHA_CONFIG,
      useValue: {
        url: `${environment.host}/captcha-votes`,
        siteKey: '0x4AAAAAAACQbae05Bv_UBmu',
      },
    },
    ...PROD_ONLY_CAPTCHA_CHALLENGE_CONFIG,
    {
      provide: SCRIPT_CONFIG,
      useValue: {
        scriptList: [
          { name: 'share-buttons', src: 'assets/vendors/share-buttons/src/share-buttons.js' },
          { name: 'captcha', src: 'https://challenges.cloudflare.com/turnstile/v0/api.js' },
          { name: 'coinzilla', src: COINZILLA_URL },
        ],
      },
    },
    {
      provide: CHART_CONFIG,
      useValue: {
        backgroundLight: '#F5F6F8',
        backgroundDark: '#161825',
      },
    },
    {
      provide: CHALLENGE_CONFIG,
      useValue: {
        url: getChallengeUrl(environment.local_prod_url),
      },
    },
    {
      provide: ANALYTICS_CONFIG,
      useValue: {
        app: process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'prod' ? 'dextools' : environment.app_scope.slice(1),
        urlMappingFn: getPageFromUrl,
        processLinks: PROCESS_LINKS_LIST,
        hierarchy3GetterFn: getHierarchy3,
      },
    },
    {
      provide: SECURED_URLS,
      useValue: {
        urls: getSecuredUrls(),
      },
    },
    {
      provide: BREAKPOINTS_CONFIG,
      useValue: {
        smallMobile: Breakpoints.XSmall,
        mobile: BREAKPOINT_MOBILE,
        tablet: BREAKPOINT_MOBILE_TABLET,
        desktop: BREAKPOINT_DESKTOP,
      },
    },
  ],
};
