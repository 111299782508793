<aside #sidebar class="main-sidebar px-0" [ngClass]="{ open: showMenu }">
  <div class="main-navbar">
    <nav class="navbar justify-content-between navbar-light flex-md-nowrap p-0">
      <div class="navbar-brand w-100 me-0">
        <div id="mainLogo">
          <a class="align-left" [appRouterLink]="AppPage.Dashboard" [appInterestLinkData]="getEventData('DEXTboard')">
            @if (!showMenu && !sidebarExpanded) {
              <img class="short-logo" ngSrc="assets/img/logo/dextools_logo.png" alt="dextools logo small" height="40" width="40" priority />
            } @else {
              <img
                @hoverAnimation
                class="large-logo"
                [ngSrc]="'assets/img/logo/dextools_logo_full_' + (theme === Theme.Dark ? 'dark' : 'light') + '.svg'"
                alt="dextools logo"
                height="30"
                width="97"
              />
            }
          </a>
          @if (showMenu) {
            <button type="button" class="close" aria-label="Close" (click)="toggleMenu()">
              <fa-icon [icon]="icons.faTimes" [tp]="'home.close' | translate"></fa-icon>
            </button>
          }
        </div>
      </div>
    </nav>
  </div>
  <div class="nav-wrapper">
    @if (isPageAvailableInChain(AppPage.Dashboard, chainSelected)) {
      <ul class="nav nav--no-borders flex-column">
        <li class="nav-item">
          <a
            class="nav-link"
            [class.active]="isRouteActive('/' + language + AppPage.Dashboard + chainSelected, true) || isRouteActive('/' + language, true)"
            [href]="context + '/' + language + '/' + chainSelected"
            (click)="navigateToPage($event, '/' + chainSelected, true, chainSelected); (false)"
            [appInterestLinkData]="getEventData('DEXTboard')"
          >
            <div>
              <fa-icon [icon]="icons.faHouse" size="lg"></fa-icon>
            </div>
            <span>{{ 'header.home' | translate }}</span>
          </a>
        </li>
      </ul>
    }
    <ul class="nav nav--no-borders flex-column list-container">
      @if (isPageAvailableInChain(AppPage.PairExplorer, chainSelected)) {
        <li class="nav-item">
          <a
            class="nav-link"
            [class.active]="isRouteActive('/' + language + '/' + chainSelected + '/' + AppPage.PairExplorer)"
            [href]="context + '/' + language + '/' + chainSelected + '/' + AppPage.PairExplorer + '/' + chainDefaultPair"
            (click)="
              navigateToPage($event, '/' + chainSelected + '/' + AppPage.PairExplorer + '/' + chainDefaultPair, false, chainSelected);
              (false)
            "
            [appInterestLinkData]="getEventData('Pair Explorer')"
          >
            <div>
              <app-icon name="pairExplorer" [width]="20"></app-icon>
            </div>
            <span>{{ 'header.pair_explorer' | translate }}</span>
          </a>
        </li>
      }
      @if (isPageAvailableInChain(AppPage.LiveNewPairs, chainSelected)) {
        <li class="nav-item">
          <a
            class="nav-link"
            [class.active]="isRouteActive('/' + language + '/' + chainSelected + '/' + AppPage.LiveNewPairs)"
            [href]="context + '/' + chainSelected + '/' + AppPage.LiveNewPairs"
            (click)="navigateToPage($event, '/' + chainSelected + '/' + AppPage.LiveNewPairs); (false)"
            [appInterestLinkData]="getEventData('Live New Pairs')"
          >
            <div>
              <app-icon name="liveNewPairs" [width]="20"></app-icon>
            </div>
            <span [innerHTML]="'header.pool_explorer' | translate"></span>
          </a>
        </li>
      }
      @if (isPageAvailableInChain(AppPage.BigSwap, chainSelected)) {
        <li class="nav-item">
          <a
            class="nav-link"
            [class.active]="isRouteActive('/' + language + '/' + chainSelected + '/' + AppPage.BigSwap)"
            [href]="context + '/' + language + '/' + chainSelected + '/' + AppPage.BigSwap"
            (click)="navigateToPage($event, '/' + chainSelected + '/' + AppPage.BigSwap); (false)"
            [appInterestLinkData]="getEventData('Big Swap Explorer')"
          >
            <div>
              <app-icon name="bigSwap" transform="scale(1.2)" [width]="20"></app-icon>
            </div>
            <span>{{ 'header.big_swap_explorer' | translate }}</span>
          </a>
        </li>
      }
      <li class="nav-item">
        <a class="nav-link" [href]="tokenCreatorUrl" target="_blank">
          <div>
            <fa-icon [icon]="icons.faFileCircleCheck" size="lg"></fa-icon>
          </div>
          <span class="user-account">Token Creator</span>
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="isRouteActive('/' + language + '/' + AppPage.Multichart)"
          [href]="context + '/' + language + '/' + AppPage.Multichart"
          (click)="navigateToPage($event, '/' + AppPage.Multichart); (false)"
          [appInterestLinkData]="getEventData('Multichart')"
        >
          <div>
            <app-icon name="multichart" [width]="20"></app-icon>
          </div>
          <span>{{ 'header.multichart' | translate }}</span>
        </a>
      </li>
      @if (isPageAvailableInChain(AppPage.Multiswap, chainSelected)) {
        <li class="nav-item">
          <a
            class="nav-link"
            [class.active]="isRouteActive('/' + language + '/' + chainSelected + '/' + AppPage.Multiswap)"
            [href]="context + '/' + language + '/' + chainSelected + '/' + AppPage.Multiswap"
            (click)="navigateToPage($event, '/' + chainSelected + '/' + AppPage.Multiswap); (false)"
            [appInterestLinkData]="getEventData('Multiswap')"
          >
            <div>
              <app-icon name="multiswap" [width]="20"></app-icon>
            </div>
            <span>{{ 'header.multiswap' | translate }}</span>
          </a>
        </li>
      }
      @if (isPageAvailableInChain(AppPage.Airdrops, chainSelected) && isAirdropsAvailable) {
        <li class="nav-item">
          <div class="position-relative bg-container">
            <span class="badge bg-info new-tag parent-option-new">{{ 'common.new_feature_badge' | translate }}</span>
          </div>
          <a
            class="nav-link"
            [class.active]="isRouteActive('/' + language + '/' + AppPage.Airdrops)"
            [href]="context + '/' + language + '/' + AppPage.Airdrops"
            (click)="navigateToPage($event, '/' + AppPage.Airdrops); (false)"
            [appInterestLinkData]="getEventData('Airdrops')"
          >
            <div>
              <fa-icon [icon]="icons.faParachuteBox" size="xl"></fa-icon>
            </div>
            <span>{{ 'header.airdrops' | translate }}</span>
          </a>
        </li>
      }
      @if (isPageAvailableInChain(AppPage.Stats, chainSelected)) {
        <li class="nav-item">
          <a
            class="nav-link"
            [class.active]="isRouteActive('/' + language + '/' + chainSelected + '/' + AppPage.Stats)"
            [href]="context + '/' + language + '/' + chainSelected + '/' + AppPage.Stats"
            (click)="navigateToPage($event, '/' + chainSelected + '/' + AppPage.Stats); (false)"
            [appInterestLinkData]="getEventData('Stats')"
          >
            <div>
              <app-icon name="stats" [width]="20"></app-icon>
            </div>
            <span>{{ 'header.stats' | translate }}</span>
          </a>
        </li>
      }
      @if (isPageAvailableInChain(AppPage.WalletInfo, chainSelected)) {
        <li class="nav-item">
          <a
            class="nav-link"
            [class.disable-link]="isNotConnected"
            [tp]="walletInfoTooltip"
            [tpIsEnabled]="isNotConnected"
            [tpTrigger]="mediaDevice() !== 'desktop' ? 'click' : 'mouseenter'"
            [class.active]="
              isRouteActive('/' + language + '/' + chainSelected + '/' + AppPage.WalletInfo + (this.userId ? '/' + this.userId : ''))
            "
            [href]="context + '/' + language + '/' + chainSelected + '/' + AppPage.WalletInfo + (this.userId ? '/' + this.userId : '')"
            (click)="
              navigateToPage($event, '/' + chainSelected + '/' + AppPage.WalletInfo + (this.userId ? '/' + this.userId : '')); (false)
            "
            [appInterestLinkData]="getEventData('Wallet Info')"
          >
            <div>
              <app-icon name="walletInfo" [width]="20"></app-icon>
            </div>
            <span>{{ 'header.wallet_info' | translate }}</span>
          </a>
        </li>
      }
      <li class="nav-item">
        <a class="nav-link" [href]="userAreaUrl" target="_blank" [appInterestLinkData]="getEventData('User Area')">
          <div>
            <fa-icon [icon]="icons.faUser" size="lg"></fa-icon>
          </div>
          <span class="user-account">{{ 'header.user_area' | translate }}</span>
        </a>
      </li>
      @if (isPageAvailableInChain(AppPage.BaseFun, chainSelected)) {
        <li class="nav-item">
          <a
            class="nav-link"
            [class.active]="isRouteActive('/' + language + '/' + AppPage.BaseFun)"
            [href]="context + '/' + language + '/' + chainSelected + '/' + AppPage.BaseFun"
            (click)="navigateToPage($event, '/' + chainSelected + '/' + AppPage.BaseFun); (false)"
            [appInterestLinkData]="getEventData('BaseFun')"
          >
            <div>
              <img ngSrc="assets/img/basefunicon.png" alt="Icon" width="20" height="20" />
            </div>
            <span class="user-account">DX Fun</span>
          </a>
        </li>
      }
      <li class="nav-item">
        @if (showProducts) {
          <ul *ifDeviceSize="'desktop'" [class]="'nav nav--no-borders flex-column group-submenu-desktop product-list ' + productListClass">
            <ng-container [ngTemplateOutlet]="productList"></ng-container>
          </ul>
        }

        <a
          #productsLink
          class="nav-link group-menu-item"
          href="javascript:"
          [attr.datatype]="'products'"
          (click)="toggleProducts(); (false)"
        >
          <div>
            <fa-icon class="fa-menu-icon" [icon]="showProducts ? icons.faMinusCircle : icons.faPlusCircle"></fa-icon>
          </div>
          <span>{{ 'header.products' | translate }}</span>
        </a>
      </li>
      @if (showProducts) {
        <ul *ifDeviceSize="'<=tablet'" class="nav nav--no-borders flex-column nav-item group-submenu-mobile">
          <ng-container [ngTemplateOutlet]="productList"></ng-container>
        </ul>
      }
    </ul>
    <h6 class="main-sidebar__nav-title" [ngClass]="{ 'align-left': !!sidebarExpanded || mediaDevice() !== 'desktop' }">{{
      'header.extra' | translate
    }}</h6>
    <ul class="nav nav--no-borders flex-column">
      <li class="nav-item">
        <a
          class="nav-link academy-link"
          [attr.datatype]="'dextools-simulator'"
          [class.active]="isRouteActive('/' + language + '/' + AppPage.Simulator)"
          [href]="context + '/' + language + '/' + AppPage.Simulator"
          (click)="navigateToPage($event, '/' + AppPage.Simulator); (false)"
          [appInterestLinkData]="getEventData('Simulator')"
        >
          <fa-icon class="youtube-icon" [icon]="icons.faChartMixedUpCircleDollar"></fa-icon>
          <span>Simulator</span>
        </a>
      </li>
      <!-- TODO- UNCOMMENT WHEN THERE IS A NEW CHALLENGE -->
      <!--      <li class="nav-item">-->
      <!--        <div class="position-relative bg-container">-->
      <!--          <span class="badge bg-info new-tag challenge-new">{{ 'common.new_feature_badge' | translate }}</span>-->
      <!--        </div>-->
      <!--        <a-->
      <!--          class="nav-link academy-link"-->
      <!--          [attr.datatype]="'dextools-challenge'"-->
      <!--          [class.active]="isRouteActive('/' + language + '/' + AppPage.Challenge)"-->
      <!--          [href]="context+'/'+language+'/'+AppPage.Challenge"-->
      <!--          (click)="navigateToPage($event, '/' + AppPage.Challenge); (false)"-->
      <!--        >-->
      <!--          <fa-icon class="youtube-icon" [icon]="icons.faTrophy"></fa-icon>-->
      <!--          <span>Challenge</span>-->
      <!--        </a>-->
      <!--      </li>-->
      @if (isPageAvailableInChain(AppPage.Dextswap, chainSelected)) {
        <li class="nav-item">
          <a
            class="nav-link"
            [class.active]="isRouteActive('/' + language + '/' + chainSelected + '/' + AppPage.Dextswap)"
            [href]="context + '/' + language + '/' + chainSelected + '/' + AppPage.Dextswap"
            (click)="navigateToPage($event, '/' + chainSelected + '/' + AppPage.Dextswap); (false)"
            [appInterestLinkData]="getEventData('DEXTswap')"
          >
            <div>
              <fa-icon [icon]="icons.faDisplayChartUp" size="lg"></fa-icon>
            </div>
            <span>DEXTswap</span>
          </a>
        </li>
      }
      <li class="nav-item">
        <a class="nav-link" target="_blank" rel="noopener" href="https://info.dextools.io/" [appInterestLinkData]="getEventData('Web')">
          <div>
            <fa-icon [icon]="icons.faGlobe" class="fa-menu-icon"></fa-icon>
          </div>
          <span>Web</span>
        </a>
      </li>
    </ul>
    <ng-container *ifDeviceSize="'desktop'; else notDesktop" [ngTemplateOutlet]="feedback"></ng-container>

    <ng-template #notDesktop>
      @if (!!userId) {
        <ul class="nav nav--no-borders flex-column">
          <li class="nav-item">
            <a class="nav-link" href="#" (click)="goToUserAccount(); (false)" [appInterestLinkData]="getEventData('Connect')">
              <div>
                <fa-icon [icon]="icons.faFlag" size="lg"></fa-icon>
              </div>
              <span class="user-account">
                <span>{{ 'header.your_plan' | translate }}</span>
                <span class="badge" [ngClass]="roleStr">{{ roleStr }}</span>
              </span>
            </a>
          </li>
        </ul>
      }
      <ul class="nav nav--no-borders flex-column">
        <li class="nav-item">
          <div tabindex="0" class="nav-link" (click)="toggleSettings(true)">
            <div>
              <fa-icon [icon]="icons.faGear" size="lg"></fa-icon>
            </div>
            <span>{{ 'header.configuration' | translate }}</span>
          </div>
        </li>
      </ul>
      <ng-container [ngTemplateOutlet]="feedback"></ng-container>
      <div class="user-connection">
        @if (!!userId) {
          <button type="button" class="btn-primary btn-disconnect" (click)="disconnect()">{{
            'user.disconnect_button' | translate
          }}</button>
        } @else {
          <button type="button" class="btn-primary btn-connect" (click)="goToUserAccount()">{{ 'user.connect_button' | translate }}</button>
        }
      </div>
    </ng-template>
  </div>
</aside>

@if (toggleSettingsPanel) {
  @defer (on immediate) {
    <app-panel-component
      *ifDeviceSize="'<=tablet'"
      [backdrop]="false"
      (panelClosed)="toggleSettings(false)"
      [panelTitle]="'config.settings' | translate"
      [panelSubtitle]="'config.parameters' | translate"
      [closeButton]="false"
      [relativeButton]="true"
      [maxHeight]="true"
    >
      <app-settings-panel></app-settings-panel>
    </app-panel-component>
  }
}

<ng-template #feedback>
  <div class="update-token-container position-relative" [ngClass]="{ mobile: mediaDevice() !== 'desktop', open: !!sidebarExpanded }">
    @if (showSocialsPromo) {
      @defer (on immediate) {
        <app-promo-tag top="-14"></app-promo-tag>
      }
    }
    <button type="button" (click)="openSocialModalOrPanel()" [appInterestLinkData]="getEventData('Update token info')">
      <fa-icon [icon]="icons.faCommentAltEdit"></fa-icon>
      <span>Update token info</span>
    </button>
  </div>
</ng-template>

<ng-template #productList>
  <li class="nav-item product-list">
    <a
      class="nav-link"
      href="https://t.me/dextbuybot"
      target="_blank"
      rel="noopener"
      [attr.datatype]="'buy-bot'"
      [appInterestLinkData]="getEventData('Buy Bot')"
    >
      <div>
        <fa-icon [icon]="icons.faMoneyCheckDollar"></fa-icon>
      </div>
      <span>Buy Bot</span>
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      href="https://t.me/DexTools_Pricebot?start=start"
      target="_blank"
      rel="noopener"
      [attr.datatype]="'price-bot'"
      [appInterestLinkData]="getEventData('Price Bot')"
    >
      <div>
        <app-icon name="priceBot" [width]="20"></app-icon>
      </div>
      <span>{{ 'home.price_bot' | translate }}</span>
    </a>
  </li>

  @if (chainNewPairsBotUrl != null) {
    <li class="nav-item">
      <a
        class="nav-link"
        [href]="chainNewPairsBotUrl"
        target="_blank"
        rel="noopener"
        [attr.datatype]="'pool-bot'"
        [appInterestLinkData]="getEventData('New Pairs Bot')"
      >
        <div>
          <app-icon name="newPairsBot" [width]="20"></app-icon>
        </div>
        <span>{{ 'home.telegram_bot' | translate }}</span>
      </a>
    </li>
  }
  <li class="nav-item">
    <a
      class="nav-link"
      [href]="appStoreUrl.ios"
      target="_blank"
      rel="noopener"
      [attr.datatype]="'ios-app'"
      [appInterestLinkData]="getEventData('IOS App')"
    >
      <div>
        <fa-icon class="fa-menu-icon" [icon]="icons.faApple"></fa-icon>
      </div>
      <span>IOS App</span>
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      [href]="appStoreUrl.android"
      target="_blank"
      rel="noopener"
      [attr.datatype]="'android-app'"
      [appInterestLinkData]="getEventData('Android App')"
    >
      <div>
        <fa-icon class="fa-menu-icon" [icon]="icons.faAndroid"></fa-icon>
      </div>
      <span>Android App</span>
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      href="https://github.com/dextools-io/chart-widget"
      target="_blank"
      rel="noopener"
      [attr.datatype]="'embedded-chart'"
      [appInterestLinkData]="getEventData('Embedded chart')"
    >
      <div>
        <fa-icon class="fa-menu-icon" [icon]="icons.faCode"></fa-icon>
      </div>
      <span>{{ 'header.embedded_chart' | translate }}</span>
    </a>
  </li>

  <li class="nav-item">
    <a
      class="nav-link"
      href="https://github.com/dextools-io/aggregator-widget"
      target="_blank"
      rel="noopener"
      [attr.datatype]="'embedded-dex-aggregator'"
      [appInterestLinkData]="getEventData('Embedded DEX aggregator')"
    >
      <div>
        <fa-icon class="fa-menu-icon" [icon]="icons.faCode"></fa-icon>
      </div>
      <span>{{ 'header.embedded_aggregator' | translate }}</span>
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      href="https://developer.dextools.io/"
      target="_blank"
      rel="noopener"
      [attr.datatype]="'api'"
      [appInterestLinkData]="getEventData('API')"
    >
      <div>
        <fa-icon class="fa-menu-icon" [icon]="icons.faGearCode"></fa-icon>
      </div>
      <span>API</span>
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link" target="_blank" rel="noopener" href="https://burn.dextools.io/">
      <div>
        <fa-icon [icon]="icons.faFire" class="fa-menu-icon"></fa-icon>
      </div>
      <span>Burn</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link academy-link" href="javascript:" [attr.datatype]="'dextools-academy'" (click)="showVideoModal(); (false)">
      <fa-icon class="youtube-icon" [icon]="icons.faYoutube"></fa-icon>
      <span>{{ 'header.dextools_academy' | translate }}</span>
    </a>
  </li>
</ng-template>

<!-- Update links panel -->
@if (showUpdateLinksPanel) {
  @defer (on immediate) {
    <app-panel-component (panelClosed)="toggleUpdateLinksPanel(false)" [closeButton]="true" [showHeader]="false">
      <app-social-icons-panel></app-social-icons-panel>
    </app-panel-component>
  }
}

<ng-template #walletInfoTooltip>
  {{ 'header.need_connected' | translate }}
</ng-template>
