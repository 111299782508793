import type { ProcessLinkData } from '@dextools/analytics';

export const PROCESS_LINKS_LIST: ProcessLinkData[] = [
  {
    id: 'connectWallet',
    processName: 'Connect Wallet',
    event: 'connect_wallet',
    eventCategory: 'user_area',
    eventAction: 'click_action',
    steps: [
      { processStep: 'Step 1', eventName: 'click_connect' },
      { processStep: 'Paso 2 ', eventName: 'connected' },
      { processStep: 'Paso 3 ', eventName: 'click_verify' },
      { processStep: 'Paso 4 ', eventName: 'finalized' },
    ],
  },
];
