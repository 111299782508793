import type { EnvironmentProviders } from '@angular/core';
import { makeEnvironmentProviders } from '@angular/core';
import type { SolanaWalletProviderConfig } from './models/wallet-provider.model';
import { SOLANA_WALLET_STORE_CONFIG } from './models/wallet-provider.model';
import { SolanaWalletStore } from './store/wallet.store';

/**
 * Provide the configuration for the Solana wallet store.
 *
 * @param customConfig - The custom configuration.
 * @returns The environment providers.
 */
export function provideSolanaWalletStoreConfig(customConfig: Partial<SolanaWalletProviderConfig> = {}): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: SOLANA_WALLET_STORE_CONFIG,
      useValue: {
        autoConnect: false,
        localStorageKey: 'solanaWalletName',
        ...customConfig,
      } as SolanaWalletProviderConfig,
    },
    SolanaWalletStore,
  ]);
}
