import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { filter, merge, Subject, takeUntil, timer } from 'rxjs';
import type { Chain } from '@dextools/blockchains';
import type { AnnouncementWithStatus } from '../../services/announcements/announcements.service';
import { AnnouncementsService } from '../../services/announcements/announcements.service';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const ANNOUNCEMENT_INTERVAL_VALIDITY_CHECK = 60_000; // 1 minute

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FontAwesomeModule],
})
export class AnnouncementComponent implements OnInit {
  @Input()
  public set chain(value: Chain) {
    this._chain = value;
    this._chainChanged$.next();
    this.announcement = null;

    this._subscribeToAnnouncements(this._chain);
  }

  public get chain(): Chain {
    return this._chain;
  }

  private _chain: Chain;

  protected announcement: AnnouncementWithStatus | null;

  private readonly _chainChanged$ = new Subject<void>();
  private readonly _newAnnouncement$ = new Subject<void>();
  private readonly _announcementViewed$ = new Subject<void>();

  private readonly _destroyRef = inject(DestroyRef);

  protected readonly icons = { faBullhorn, faTimes };

  public constructor(
    private readonly _announcementsService: AnnouncementsService,
    private readonly _router: Router,
    private readonly _cdRef: ChangeDetectorRef,
  ) {
    // noop
  }

  public ngOnInit() {
    this._router.events.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this._markAnnouncementAsViewed();
      }
    });

    this._chainChanged$.subscribe();
  }

  protected get announcementContent(): string {
    return this.announcement?.contentHtml ?? '';
  }

  protected get announcementTitle(): string | null {
    return this.announcement?.titleHtml ?? '';
  }

  protected closeAnnouncement() {
    this._markAnnouncementAsViewed();
  }

  private _subscribeToAnnouncements(chain: Chain) {
    this._announcementsService
      .getAnnouncementsByChain(chain)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        takeUntil(this._chainChanged$),
        filter((announcement) => this._isAnnouncementValid(announcement)),
      )
      .subscribe((announcementData) => {
        this.announcement = announcementData;
        this._newAnnouncement$.next();
        this._setAnnouncementAutoClose();
        this._cdRef.detectChanges();
      });
  }

  private _isAnnouncementValid(announcement: AnnouncementWithStatus | null): boolean {
    return !!announcement && !announcement.alreadyViewed && this._announcementsService.isAnnouncementValid(announcement);
  }

  private _setAnnouncementAutoClose() {
    const stopAutoCloseInterval$ = merge(this._chainChanged$, this._newAnnouncement$, this._announcementViewed$);

    // check every X minutes, starting in X minutes
    timer(ANNOUNCEMENT_INTERVAL_VALIDITY_CHECK, ANNOUNCEMENT_INTERVAL_VALIDITY_CHECK)
      .pipe(takeUntilDestroyed(this._destroyRef), takeUntil(stopAutoCloseInterval$))
      .subscribe(() => {
        if (!this._isAnnouncementValid(this.announcement)) {
          this._markAnnouncementAsViewed();
        }
      });
  }

  private _markAnnouncementAsViewed() {
    if (this.announcement != null) {
      this._announcementsService.markAnnouncementAsViewed(this.announcement);
      this.announcement = null;
      this._announcementViewed$.next();
      this._cdRef.detectChanges();
    }
  }
}
